













































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios, { AxiosResponse } from 'axios'
import { Product } from '@/models/Product.model'
import { User } from '@/models/User.model'

@Component
export default class ProductDetailsView extends Vue {
  @Prop()
  private productId!: number
  private product: Product | null = null
  private productMembers: User[] = []
  private joinCodeCopied = false
  private appUrl = ''
  private joinUrl = ''

  async created() {
    this.appUrl = process.env.VUE_APP_BASE_URL
    this.product = await Product.createFromResponse(await axios.get<AxiosResponse>('/products/' + this.productId))
    const users = await axios.get<any>(`/users?productMembers.product=${this.product.iri()}`)

    users.data['hydra:member'].map((userData: any) => this.productMembers.push(User.createFromData(userData)))
    this.joinUrl = `${this.appUrl}product/initial/${this.product.joinCode}`
  }

  OnJoinCodeCopied() {
    this.joinCodeCopied = true
    setTimeout(this.hideJoinCodeCopied, 3000)
  }

  hideJoinCodeCopied() {
    this.joinCodeCopied = false
  }

  get currentUser(): User {
    return this.$store.state.auth.currentUser
  }
}
